import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import {
  AppGoalCenterWrapper,
  AppGoalCenterHeading,
  AppGoalCenterSubHeading,
  AppCountCardWrapper,
  AppCard,
  AppContent,
  AppCountWrapper,
  AppCountBubble,
  AppCount,
  Divider,
} from 'components/AppGoalCenter/AppGoalCenter.style'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'

import {
  BusinessGoalsFilterData,
  useGetBusinessGoalsQuery,
} from '__generated__/api-types-and-hooks'
import { getTenantId } from 'utils/getTenantId'

import { BusinessGoal } from '__generated__/api-types-and-hooks'
import { RootState } from 'App'
import AppSidebar from './AppSidebar/AppSidebar.component'
import { AppGoals } from './AppGoals/AppGoals.component'
import { tasksCompletedCount } from 'appUtils'

const AppGoalCenter: React.FC = () => {
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  const tenantId = getTenantId()
  const [selectedFilters, setSelectedFilters] = useState<BusinessGoalsFilterData>({})
  const { data, isLoading, refetch } = useGetBusinessGoalsQuery({
    input: { userId: clientId, tenantId, filter: selectedFilters },
  })

  return (
    <div className="flex flex-col sm:flex-row gap-y-4 w-full">
      {!isLoading && (
        <AppSidebar setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />
      )}
      <div className="flex-1 flex flex-col gap-8 py-7 xs:px-5 sm:px-6 md:px-12 lg:px-[6.25rem] ">
        {!isLoading && (
          <>
            <AppGoalCenterWrapper>
              <AppGoalCenterHeading>Welcome To Your Goal Center!</AppGoalCenterHeading>
              <AppGoalCenterSubHeading>
                Below you will find your goals. Click on each to work towards it.
              </AppGoalCenterSubHeading>
            </AppGoalCenterWrapper>

            <AppCountCardWrapper>
              <AppCard>
                <AppContent>
                  <h4 className="text-white font-inter font-bold text-base">
                    Touchdowns Completed
                  </h4>
                </AppContent>
                <AppCountWrapper>
                  <AppCountBubble>
                    <AppCount>0</AppCount>
                  </AppCountBubble>
                </AppCountWrapper>
              </AppCard>

              <AppCard>
                <AppContent>
                  <h4 className="text-white font-inter font-bold text-base">Tasks Completed</h4>
                </AppContent>
                <AppCountWrapper>
                  <AppCountBubble>
                    <AppCount>{tasksCompletedCount(data?.getBusinessGoals)}</AppCount>
                  </AppCountBubble>
                </AppCountWrapper>
              </AppCard>
            </AppCountCardWrapper>

            <Divider />
          </>
        )}

        <div>
          {isLoading ? (
            <div className="flex justify-center items-center">
              <GradientCircleNotchIcon width="100" height="100" />
            </div>
          ) : (
            <AppGoals
              goals={data?.getBusinessGoals as BusinessGoal[]}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              refetchGoals={refetch}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AppGoalCenter
