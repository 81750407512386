import React, { FC, useEffect, useState } from 'react'

import {
  AppBackgroundBar,
  AppCardWrapper,
  AppProgressBarWrapper,
  AppProgressContainer,
  AppProgressFill,
  AppProgressLabel,
  AppCardDetails,
  AppCardFooter,
} from 'components/AppGoalCenter/AppGoalCard/AppGoalCard.style'
import {
  BusinessGoalPriority,
  UserTaskItemStatus,
  useUpdateBusinessGoalMutation,
} from '__generated__/api-types-and-hooks'
import { categoryType, goalPriorityOptions, goalPriorityStatus } from 'appConfig/data'
import { getTenantId } from 'utils/getTenantId'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { AppConfirmationModal } from 'components/AppConfirmationModal/AppConfirmationModal.component'
import ThreeDotsIcon from 'components/Common/SvgIcons/ThreeDotsIcon'
import moment from 'moment'
import { AppSelectField } from 'components/Common/AppFormFields/AppSelectField/AppSelectField.component'
import { AppTextField } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'

interface ICardProps {
  goalId: string
  title: string
  progress: number | null
  image?: string
  description: string
  category: string
  priority: string
  expectedEndDate?: string
  goalStatus?: UserTaskItemStatus | null
  logo: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  refetchGoals?: () => void
  flagValueUser?: boolean
  allTaskTodo: boolean
}

export const AppCard: FC<ICardProps> = ({
  goalId,
  title,
  progress,
  logo,
  priority,
  category,
  expectedEndDate,
  goalStatus,
  onClick,
  refetchGoals,
  flagValueUser,
  description,
  allTaskTodo,
}) => {
  const tenantId = getTenantId()
  const [goalPriority, setGoalPriority] = useState(goalPriorityStatus[priority])
  const [goalDueDate, setGoalDueDate] = useState(expectedEndDate)
  const [changeCardValue, setChangeCardValue] = useState({ dueDate: false, priority: false })
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [prioritysChipColor, setPriorityChipColor] = useState(priority)
  const [, setShowConfetti] = useState(false)
  const { mutate, isLoading, isSuccess, error, isError } = useUpdateBusinessGoalMutation()
  const userId = useSelector((state: RootState) => state.user.user.id)
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  let statusChipColor = getStatusChipColor(prioritysChipColor)

  const handleMarkGoalAsDone = () => {
    mutate({
      input: {
        goalId,
        tenantId,
        status: UserTaskItemStatus.Done,
        progress: 100,
        userId: clientId,
      },
    })
  }
  useEffect(() => {
    if (goalStatus !== UserTaskItemStatus.Done && progress === 100) {
      handleMarkGoalAsDone()
    } else if (goalStatus === UserTaskItemStatus.Done && progress !== 100) {
      mutate({
        input: {
          goalId,
          tenantId,
          status: allTaskTodo ? UserTaskItemStatus.Todo : UserTaskItemStatus.InProgress,
          progress,
          userId: clientId,
        },
      })
    }
    // eslint-disable-next-line
  }, [progress, allTaskTodo])

  useEffect(() => {
    if (isSuccess && refetchGoals) refetchGoals()

    if (isSuccess && !isConfirmModalOpen) {
      setShowConfetti(true)
      setTimeout(() => setShowConfetti(false), 10000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isConfirmModalOpen])

  const handleClick = (field: string, value: boolean) => {
    setChangeCardValue((prev) => ({ ...prev, [field]: value }))
  }

  return (
    <AppCardWrapper onClick={onClick}>
      <AppCardDetails>
        <div className="flex items-center gap-2 justify-between">
          {goalStatus === UserTaskItemStatus.Creating ? (
            <Chip className={`bg-app-green-25 text-app-green-65 w-16`} content="Creating Plan" />
          ) : !changeCardValue.priority ? (
            <Chip
              className={`${statusChipColor}`}
              content={goalPriority}
              onClick={() => handleClick('priority', true)}
            />
          ) : (
            <div className="w-1/2" onClick={(event) => event.stopPropagation()}>
              <AppSelectField
                size="sm"
                options={goalPriorityOptions}
                placeholder="Select status"
                value={priority}
                onChange={(e) => {
                  setGoalPriority(goalPriorityStatus[e.value])
                  setPriorityChipColor(e.value)
                  mutate({
                    input: {
                      goalId,
                      tenantId,
                      goalPriority: e.value,
                      userId,
                    },
                  })
                  handleClick('priority', false)
                }}
                onBlur={() => handleClick('priority', false)}
              />
            </div>
          )}

          <ThreeDotsIcon />
        </div>

        <div className="flex flex-col items-start gap-2">
          <p className="text-base font-medium leading-6 text-black-appDark">{title}</p>
          <p className="text-sm font-normal leading-5 text-black-appDime">{description}</p>
        </div>

        <div className="w-full flex flex-col gap-5">
          <div className="w-full flex items-center">
            <ProgressBar progress={goalStatus === UserTaskItemStatus.Done ? 100 : progress || 0} />
          </div>

          <div className="w-full flex items-center justify-between">
            <p className="text-sm text-black-appDime">Due Date</p>
            {!changeCardValue.dueDate ? (
              moment(goalDueDate).isValid() ? (
                <Chip
                  className={`bg-app-green-25 text-app-green-65 w-16`}
                  content={`${moment(goalDueDate).format('MMMM Do, YYYY')}`}
                  onClick={() => handleClick('dueDate', true)}
                />
              ) : null
            ) : (
              <div className="w-1/2" onClick={(event) => event.stopPropagation()}>
                <AppTextField
                  size="sm"
                  type="date"
                  placeholder="Select Due Date"
                  value={goalDueDate}
                  onChange={(e) => {
                    setGoalDueDate(e.target.value)
                    mutate({
                      input: {
                        goalId,
                        tenantId,
                        expectedEndDate: e.target.value,
                        userId,
                      },
                    })
                  }}
                  onBlur={() => handleClick('dueDate', false)}
                />
              </div>
            )}
          </div>
        </div>
      </AppCardDetails>

      <AppCardFooter>
        <Chip
          className={`px-4 py-[10px] !w-fit bg-white border border-primary-appDarkBorder text-app-grey-55 shadow-sm`}
          content={categoryType[category]}
        />
      </AppCardFooter>

      {isConfirmModalOpen && (
        <AppConfirmationModal
          title="Mark goal as done"
          isSuccess={isSuccess}
          isError={isError}
          isLoading={isLoading}
          errorMessage={error?.message}
          onClose={() => setIsConfirmModalOpen(false)}
          onConfirm={handleMarkGoalAsDone}
          description={`Marking goal as done means you have acheived the goal '${title}' and want to change the status to DONE`}
        />
      )}
    </AppCardWrapper>
  )
}

interface IChipProps {
  className: string
  content: string
  onClick?: () => void
}

const Chip: React.FC<IChipProps> = ({ className, content, onClick }) => {
  return (
    <div
      className={`px-2 py-[6px] rounded-lg text-sm leading-5 !w-auto h-8 text-center ${className}`}
      onClick={(event) => {
        if (onClick) {
          onClick()
        }
        event.stopPropagation()
      }}
    >
      {content}
    </div>
  )
}

interface IProgressBarProps {
  progress: number
}

const ProgressBar = ({ progress }: IProgressBarProps) => {
  return (
    <AppProgressBarWrapper>
      <AppProgressLabel>{progress}%</AppProgressLabel>
      <AppProgressContainer>
        <AppBackgroundBar>
          <AppProgressFill progress={progress} />
        </AppBackgroundBar>
      </AppProgressContainer>
    </AppProgressBarWrapper>
  )
}

const getStatusChipColor = (priority: string) => {
  switch (priority) {
    case BusinessGoalPriority.HighPriority:
      return 'bg-app-red-25 text-app-red-65'
    case BusinessGoalPriority.MediumPriority:
      return 'bg-app-orange-25 text-app-orange-65'
    case BusinessGoalPriority.LowPriority:
      return 'bg-app-green-25 text-app-green-65'
    default:
      return 'bg-red'
  }
}
