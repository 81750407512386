import { isEmpty } from 'lodash'
import styled from 'styled-components'
import tw from 'twin.macro'
import InputMask from 'react-input-mask'

// Wrapper for the entire text field component
export const AppFieldParentWrapper = styled.div.attrs({
  className: 'inline w-full',
})``

// Styles for the input container
export const AppFieldWrapper = styled.div<{
  prefix?: string
  error: boolean
  disabled: boolean
  size: 'lg' | 'md' | 'sm'
  children?: React.ReactNode
}>`
  ${tw`rounded-lg flex relative w-full gap-2 items-center`}

  ${({ disabled }) =>
    disabled && tw`bg-app-grey-20 border border-app-grey-40 appShadow-s cursor-not-allowed`}
  ${({ error }) => error && tw`bg-app-red-10 border border-app-red-60 appShadow-s`}
  ${({ error, disabled }) => !error && !disabled && tw`bg-app-white-100 border border-app-grey-40`}

  &:hover {
    ${tw`border border-app-grey-50 appShadow-s`}
  }
  &:focus-within {
    ${tw`bg-app-white-100 border border-app-primary-60 appShadow-focus`}
  }

  ${({ size, prefix }) => !prefix && size === 'lg' && tw`px-4 py-3.5`}
  ${({ size, prefix }) => !prefix && size === 'md' && tw`px-4 py-2.5`}
  ${({ size, prefix }) => !prefix && size === 'sm' && tw`px-3 py-1.5`}
`

// Styles for the input element
export const AppInputWrapper = styled(InputMask)`
  ${tw`
    w-full
    outline-0
    placeholder-app-grey-50
    text-app-body-regular-font-size 
    font-app-body-regular-font-family 
    font-app-body-regular-font-weight
    leading-app-body-regular-line-height
    tracking-app-body-regular-letter-spacing
    
  `}
  ${({ error }) => (!isEmpty(error) ? tw`bg-app-red-10` : '')}

  &:focus {
    ${tw`bg-app-white-100`}
  }
  ${({ disabled }) =>
    disabled
      ? tw`placeholder-app-grey-50 bg-app-grey-20 text-app-grey-50 cursor-not-allowed`
      : 'text-app-black-100'}
`

export const AppErrorWrapper = styled.div.attrs({
  className: 'flex mt-2 gap-2 items-center',
})``

export const AppErrorMessageWrapper = styled.div.attrs({
  className: `
    text-app-red-70 
    text-app-body-regular-font-size 
    font-app-body-regular-font-family 
    font-app-body-regular-font-weight
    leading-app-body-regular-line-height
    tracking-app-body-regular-letter-spacing`,
})``

export const AppLabelWrapper = styled.label.attrs({
  className: `
    mb-2
    flex
    w-full
    text-app-body-regular-font-size 
    font-app-body-regular-font-family 
    font-app-body-regular-font-weight
    leading-app-body-small-line-height
    tracking-app-body-regular-letter-spacing`,
})``

export const AppPrefixWrapper = styled.div<{
  size: 'lg' | 'md' | 'sm'
}>`
  ${tw`text-app-grey-70 text-app-body-regular-font-size font-app-body-regular-font-family font-app-body-regular-font-weight leading-app-body-regular-line-height tracking-app-body-regular-letter-spacing whitespace-nowrap
  `}

  ${({ size }) => size === 'lg' && tw`px-4 py-3.5 pr-1`}
  ${({ size }) => size === 'md' && tw`px-4 py-2.5 pr-1`}
  ${({ size }) => size === 'sm' && tw`px-3 py-1.5 pr-1`}
`

export const AppPrefixDividerWrapper = styled.div<{
  size: 'lg' | 'md' | 'sm'
}>`
  ${tw`flex items-start relative h-10 self-stretch bg-app-grey-40 relative w-px
  `}

  ${({ size }) => size === 'lg' && tw`h-12`}
  ${({ size }) => size === 'md' && tw`h-10`}
  ${({ size }) => size === 'sm' && tw`h-8`}
`
