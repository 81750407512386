import React from 'react'

export const AppInfoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="20px" height="20px">
      <path
        fill="#8bb7f0"
        d="M40,77.5C19.322,77.5,2.5,60.678,2.5,40S19.322,2.5,40,2.5S77.5,19.322,77.5,40S60.678,77.5,40,77.5 z"
      />
      <path
        fill="#4e7ab5"
        d="M40,3c20.402,0,37,16.598,37,37S60.402,77,40,77S3,60.402,3,40S19.598,3,40,3 M40,2 C19.013,2,2,19.013,2,40s17.013,38,38,38s38-17.013,38-38S60.987,2,40,2L40,2z"
      />
      <path fill="#fff" d="M40 21A3 3 0 1 0 40 27A3 3 0 1 0 40 21Z" />
      <g>
        <path fill="#fff" d="M43 56L43 31 35 31 35 33 37 33 37 56 35 56 35 58 45 58 45 56z" />
      </g>
    </svg>
  )
}
